import { styled } from '@linaria/react';

export const SelectContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  width: 100%;
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Select = styled.select`
  padding-left: 1rem;
  padding-right: 2.5rem;
  text-overflow: ellipsis;
  height: 3rem;
  background-color: var(--surface);
  border: 1px solid var(--border-strong);
  border-radius: var(--radius-round);
  font-size: 1rem;
  font-family: var(--font-sans);
  color: var(--on-surface-secondary);
  outline: none;
  appearance: none;
  width: 100%;
  cursor: pointer;

  ::placeholder {
    color: var(--on-surface-tertiary);
  }

  :hover {
    background-color: var(--darken-neutral-hover);
  }

  :focus {
    background-color: var(--darken-neutral-press);
  }

  :disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

export const SelectError = styled.span`
  font-size: 0.875rem;
  font-family: var(--font-sans);
  color: var(--danger);
`;

export const Icon = styled.div`
  position: absolute;
  top: 0.9rem;
  right: 1rem;
  pointer-events: none;
`;
