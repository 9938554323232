import { styled } from '@linaria/react';

export const SelectContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  width: 100%;
`;

export const SelectLabel = styled.label`
  font-size: 0.75rem;
  font-family: var(--font-sans);
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: var(--on-surface-tertiary);
  cursor: pointer;
  width: fit-content;
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Select = styled.select`
  padding: 1rem;
  background-color: var(--surface);
  border: 1px solid var(--border-weak);
  border-radius: var(--radius-round);
  font-size: 1rem;
  font-family: var(--font-sans);
  color: var(--on-surface-secondary);
  outline: none;
  appearance: none;
  width: 100%;
  cursor: pointer;

  ::placeholder {
    color: var(--on-surface-tertiary);
  }

  :hover {
    background-color: var(--darken-neutral-hover);
  }

  :focus {
    background-color: var(--darken-neutral-press);
  }
`;

export const SelectContent = styled.div`
  width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
  border: 1px solid var(--border-hairline);
  background-color: var(--surface);
  border-radius: var(--radius-round);
  overflow: auto;
  box-shadow: var(--elevation-bottom-3);
  padding: var(--spacing-xs) 0;
  z-index: 9999;
`;

export const SelectItem = styled.button`
  position: relative;
  padding: var(--spacing-xs) var(--spacing-sm);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--spacing-md);

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 100ms;
  }

  :hover {
    border: none !important;
    outline: none !important;

    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active,
  &[data-selected='true'] {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;
