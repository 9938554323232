import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMSelectDesktop } from './RMSelect.desktop';
import { RMSelectMobile } from './RMSelect.mobile';
import { RMSelectProps } from './RMSelect.types';

export function RMSelect(props: RMSelectProps) {
  const isMobile = useIsMobileViewport();

  if (isMobile) {
    return <RMSelectMobile {...props} />;
  }

  return <RMSelectDesktop {...props} />;
}
