import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixSelect from '@radix-ui/react-select';

import { RMText } from '../RMText/RMText';

import { SelectContent, SelectItem } from './RMSelect.desktop.styles';
import { Icon, Select, SelectContainer, SelectError, SelectWrapper } from './RMSelect.mobile.styles';
import { RMSelectProps } from './RMSelect.types';

export function RMSelectDesktop({ id, label, onChange, options, error, value, disabled }: RMSelectProps) {
  return (
    <RadixSelect.Root value={value ?? undefined} onValueChange={onChange} disabled={disabled}>
      <SelectContainer>
        {label && (
          <RMText type="sans" size="xs" color="on-surface-secondary" bold>
            {label}
          </RMText>
        )}
        <RadixSelect.Trigger asChild>
          <SelectWrapper>
            <Select
              id={id}
              value={value ?? undefined}
              onChange={(event) => onChange(event.target.value)}
              disabled={disabled}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value} disabled={option.disabled}>
                  {option.label}
                </option>
              ))}
            </Select>

            <Icon>
              <FontAwesomeIcon icon={faChevronDown} color="var(--on-surface-secondary)" size="sm" />
            </Icon>
          </SelectWrapper>
        </RadixSelect.Trigger>
        {error && <SelectError>{error}</SelectError>}
      </SelectContainer>

      <RadixSelect.Content asChild position="popper" side="bottom">
        <SelectContent>
          {options
            // Hide empty options.
            .filter((o) => o.label.length > 0)
            .map((option) => (
              <RadixSelect.Item key={option.value} value={option.value} asChild>
                <SelectItem data-selected={value === option.value} disabled={option.disabled}>
                  <RMText type="sans" size="s" bold color="on-surface-secondary">
                    {option.label}
                  </RMText>
                </SelectItem>
              </RadixSelect.Item>
            ))}
        </SelectContent>
      </RadixSelect.Content>
    </RadixSelect.Root>
  );
}
