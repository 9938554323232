import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '../RMText/RMText';

import { Icon, Select, SelectContainer, SelectError, SelectWrapper } from './RMSelect.mobile.styles';
import { RMSelectProps } from './RMSelect.types';

export function RMSelectMobile({ id, label, value, error, disabled, options, onChange }: RMSelectProps) {
  return (
    <SelectContainer>
      {label && (
        <RMText type="sans" size="xs" color="on-surface-secondary" bold>
          {label}
        </RMText>
      )}

      <SelectWrapper>
        <Select
          id={id}
          value={value ?? undefined}
          onChange={(event) => onChange(event.target.value)}
          disabled={disabled}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </Select>

        <Icon>
          <FontAwesomeIcon icon={faChevronDown} color="var(--on-surface-secondary)" size="sm" />
        </Icon>
      </SelectWrapper>

      {error && <SelectError>{error}</SelectError>}
    </SelectContainer>
  );
}
